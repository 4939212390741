import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductBreads } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import OliveRosemaryBread from '../images/olive-rosemary-bread.png';
import CranberryWalnutBread from '../images/cranberry-walnut-bread.png';
import Sourdough from '../images/sourdough.png';
import FrenchBread from '../images/french-bread.png';
import BraidedChallahBread from '../images/braided-challa-loaf.png';
import PestoBread from '../images/pesto-bread.png';
import MilkBread from '../images/milk-bread.png';
import CinnamonRaisinBread from '../images/cinnamon-raisin-bread.png';
import PainDeCampagne from '../images/pain-de-campagne.png';
import LemonHerbBread from '../images/lemon-herb-bread.png';
import Foccacia from '../images/foccacia.png';
import MilkBreadRolls from '../images/japanese-milk-bread-rolls.png';
import AleRolls from '../images/ale-roll.png';
import RaspberryBread from '../images/raspberry-white-chocolate.png';
import Boule from '../images/boule.png';

function ProductBreads() {
    const enabled = useRecoilValue(viewProductBreads);

    const handleClick = (e, param) => {
        var subject = "";
        var body = "";
        var commonPostFix = " Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours. If ordering multiple items feel free to just include everything you'd like here in one email to us. The best way to reach us is by calling or texting us at (770) 504-6617 with your order or questions. We look forward to working with you!";

        switch(param) {
            case 'oliveRosemaryBread':
                subject = "Bleu Fox Bakery Online Order - Olive and Rosemay Bread";
                body = "Thank you for your interest in the Olive and Rosemary Bread.";
                break;
            case 'cranberryWalnutBread':
                subject = "Bleu Fox Bakery Online Order - Cranberry and Walnut Bread";
                body = "Thank you for your interest in the Cranberry and Walnut Bread.";
                break;
            case 'sourdough':
                subject = "Bleu Fox Bakery Online Order - Sourdough";
                body = "Thank you for your interest in the Sourdough.";
                break;
            case 'frenchBread':
                subject = "Bleu Fox Bakery Online Order - French Bread";
                body = "Thank you for your interest in the French Bread.";
                break;
            case 'boule':
                subject = "Bleu Fox Bakery Online Order - Boule";
                body = "Thank you for your interest in the Boule.";
                break;
            case 'braidedChallahBread':
                subject = "Bleu Fox Bakery Online Order - Braided Challah Bread";
                body = "Thank you for your interest in the Braided Challah Bread.";
                break;
            case 'pestoBread':
                subject = "Bleu Fox Bakery Online Order - Pesto Bread";
                body = "Thank you for your interest in the Pesto Bread.";
                break;
            case 'milkBread':
                subject = "Bleu Fox Bakery Online Order - Milk Bread";
                body = "Thank you for your interest in the Milk Bread.";
                break;
            case 'cinnamonRaisinBread':
                subject = "Bleu Fox Bakery Online Order - Cinnamon Raisin Bread";
                body = "Thank you for your interest in the Cinnamon Raisin Bread.";
                break;
            case 'painDeCampagne':
                subject = "Bleu Fox Bakery Online Order - Pain De Campagne";
                body = "Thank you for your interest in the Pain De Campagne.";
                break;
            case 'lemonHerbBread':
                subject = "Bleu Fox Bakery Online Order - Lemon and Herb Bread";
                body = "Thank you for your interest in the Lemon and Herb Bread.";
                break;
            case 'focaccia':
                subject = "Bleu Fox Bakery Online Order - Focaccia";
                body = "Thank you for your interest in the Focaccia.";
                break;
            case 'raspberryBread':
                subject = "Bleu Fox Bakery Online Order - Raspberry White Chocolate Bread";
                body = "Thank you for your interest in the Raspberry White Chocolate Bread.";
                break;
            case 'milkBreadRolls':
                subject = "Bleu Fox Bakery Online Order - Japanese Milk Bread Rolls";
                body = "Thank you for your interest in the Japanese Milk Bread Rolls.";
                break;
            case 'aleRolls':
                subject = "Bleu Fox Bakery Online Order - Ale Rolls";
                body = "Thank you for your interest in the Ale Rolls.";
                break;
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body + commonPostFix;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Sourdough}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Sourdough
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the delight of a traditional Sourdough loaf, featuring a beautiful deep brown crust with a wonderfully chewy texture, complemented by a light, partially airy crumb with a mild tang. The bread is crafted through a lengthy rising and slow proofing process, ensuring that every bite is packed with flavor. A great choice for an amazing deli sandwich, grilled cheese or toast with jam!</p>
                                        <p>Ingredients: Flour, Water, Salt, Olive Oil, Semolina and Malted Barley Flour.</p>
                                        <p>Price: $10 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'sourdough');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PainDeCampagne}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Pain de Campagne
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the joy of our Pain de Campagne, which boasts a crisp crust and lovely crumb that’s perfect for crafting sandwiches. The addition of rye flour combined with an extended rising time imparts a remarkable flavor and texture to this bread. This is a wonderfully flexible bread that you will surely enjoy.</p>
                                        <p>Ingredients: Flour, Water, Rye Flour, Salt, Yeast and Olive Oil.</p>
                                        <p>Price: $10 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'painDeCampagne');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={LemonHerbBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Lemon and Herb Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Delight in the vibrant flavors of our Lemon and Herb bread, where the aromatic herbs de Provence meet the lively brightness of lemon zest. This medium-sized loaf features a wonderfully chewy crust and a soft, airy crumb, making it a fantastic addition to your dinner table with a spread of your favorite butter. It’s also versatile enough to be enjoyed as a sandwich or simply toasted with a soothing cup of tea.</p>
                                        <p>Ingredients: Flour, Water, Whole Rye Flour, Salt, Herbes de Provence and Lemon Zest.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'lemonHerbBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={OliveRosemaryBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Olive and Rosemary Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our Olive and Rosemary bread is an ideal companion for Mediterranean cuisine, pasta dishes, or as a delightful addition to any charcuterie board featuring cheese, cured meats, and pickles. Infused with generous amounts of Kalamata olives and a subtle hint of rosemary, the flavors harmonize wonderfully. The bread boasts a crispy, chewy crust that gives way to a light, airy interior filled with olives. While it’s delicious on its own, we love to toast slices or cubes and serve them with our favorite olive oil dip and hummus.</p>
                                        <p>Ingredients: Flour, Spring Water, Whole Wheat Flour, Kalamata Olives, Salt, Fresh Rosemary and Whole Rye Flour.</p>
                                        <p>Price: $12 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'oliveRosemaryBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={CranberryWalnutBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Cranberry and Walnut Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the delightful complexity of our Cranberry and Walnut bread, where earthy and nutty notes intertwine with the gentle sweetness of cranberries, creating a harmonious flavor profile. The crust boasts a deep mahogany hue and a satisfying crispness, while the interior features a sturdy, yet tender crumb that provides a rich mouthfeel, enriched by the diverse textures of walnuts and cranberries.</p>
                                        <p>Perfect for the holiday season, this bread makes for a wonderful non-traditional treat. We love to set out a loaf to pair with our holiday meals, savor it as toast with our morning coffee, or transform it into a mouthwatering turkey sandwich.</p>
                                        <p>Ingredients: Flour, Whole Wheat Flour, Whole Rye Flour, Spring Water, Walnuts, Dried Cranberries, Salt and Walnut Oil.</p>
                                        <p>Price: $12 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'cranberryWalnutBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={FrenchBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        French Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our French bread boasts a delightful flavor, featuring a gorgeous golden crust. The inside is luxuriously soft and fluffy. This bread is incredibly versatile, making it ideal for various meals. We love using it for garlic toast, crafting PB&J sliders, or turning leftovers into scrumptious French toast or amazing croutons.</p>
                                        <p>Ingredients: Flour, Water, Sugar, Olive Oil, Salt, Yeast and Malt Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'frenchBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Boule}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Boule
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Our Boule is a delightful rustic white bread bursting with flavor and plenty of holes. This naturally-leavened round loaf features a tender, chewy center encased in a beautifully dark, crusty shell. We skip the scoring process, letting natural cracks develop during baking. With a lengthy rise, this bread delivers a rich and memorable taste. Perfect for hearty sandwiches, delicious French toast, fantastic croutons, and so much more.</p>
                                        <p>Ingredients: Flour, Water, Salt and Yeast.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'boule');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BraidedChallahBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Braided Challah Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience the delightful softness and rich flavor of our Braided Challah Bread. Its subtle sweetness makes it perfect for a classic PB&J sandwich, or you can slice it and toast it for an incredible bun. And if you’re in the mood for breakfast, it’s perfect for creating amazing French toast!</p>
                                        <p>Ingredients: Flour, Water, Eggs, Milk, Butter, Sugar, Olive Oil, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'braidedChallahBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PestoBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Pesto Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A beautiful bread infused with Basil Pesto. This bread has a crispy, slightly chewy crust with a soft, fragrant interior. Hints of Basil, Garlic, and Parmesan throughout create a beautifully balanced flavor. Perfect for sandwiches, or a sublime base for Avocado Toast with Egg.</p>
                                        <p>Ingredients: Flour, Water, Prepared Pesto, Olive Oil, Salt, Yeast, and Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'pestoBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AleRolls}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Ale Rolls
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Hearty and flavorful, these robust bread rolls deserve a place at the table alongside your favorite meal. Made with high quality ale and baked to perfection, these are sure to become your new favorite rolls. We like them with lots of butter or we often use them as sliders for a deli meat sandwich.</p>
                                        <p>Ingredients: Flour, Ale, Whole Wheat Flour, Butter, Yeast, Salt, Sunflower Oil and Olive Oil.</p>
                                        <p>Price: $6 USD (7 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'aleRolls');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MilkBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Milk Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>For centuries, milk has been a cherished ingredient in bread baking, contributing to a wonderfully soft and airy texture along with a subtle sweetness. This bread is crafted in a pullman pan, resulting in square slices and a uniform crust. It's just right for sandwiches or toast, offering a quality that outshines commercial options. For the best slicing experience, opt for a serrated knife.</p>
                                        <p>Ingredients: Flour, Milk, Butter, Sugar, Yeast, Salt, Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'milkBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={CinnamonRaisinBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Cinnamon Raisin Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This cinnamon raisin bread is incredibly flavorful with warm cinnamon in the background, packed with raisins throughout and a touch of mild sweetness giving the bread a beautiful balance.</p>
                                        <p>Can be enjoyed on it's own, as the basis for an amazing PB&J or toasted with butter, that's our favorite way to enjoy this delicious bread.</p>
                                        <p>Ingredients: Flour, Milk, Raisins, Water, Brown Sugar, Sugar, Butter, Salt, Cinnamon, Yeast and Olive Oil.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'cinnamonRaisinBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={Foccacia}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Italian Herb Focaccia
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Discover the incredible flavor of our Italian Herb Focaccia, freshly baked just for you! This light and fluffy bread is infused with aromatic herbs and finished with a sprinkle of flaky salt, creating a perfect balance of flavors. Whether you’re dipping it into savory marinara or vibrant pesto, or using it as a sandwich base, this focaccia is a versatile delight. It also makes a fantastic side for salads and soups, or try it grilled with butter for an extra special touch.</p>
                                        <p>Ingredients: Flour, Water, Olive Oil, Yeast, Salt, Oregano, Semolina and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'focacia');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MilkBreadRolls}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Japanese Milk Bread Rolls
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Also referred to as Hokkaido, our milk bread rolls are incredibly tender, soft, and airy. The milk creates a light, delicately sweet flavor. These are perfect for tear-and-share and make a great accompaniment to your family meal.</p>
                                        <p>Ingredients: Flour, Milk, Butter, Egg, Sugar, Water, Olive Oil, Dry Milk, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $6 USD (10 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'milkBreadRolls');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    {/*
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={RaspberryBread}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Raspberry White Chocolate Bread
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This bread will knock your socks off! We always make the bread with fresh raspberries ( seeds are removed for a more enjoyable eating experience ) and a generous scattering of white chocolate throughout. Looks and smells amazing, and has a wonderfully soft texture and flavor. The bread has a slight chew on the crust with the interior boasting a lightly sweet and fruity flavor, it's almost dessert but can really be eaten anytime. We love to eat this for breakfast or with a nice cup of tea. Also makes a phenomenal PB&J.</p>
                                        <p>Ingredients: Flour, Water, White Chocolate, Fresh Raspberries, Olive Oil, Sugar, Yeast, Salt and Malted Barley Flour.</p>
                                        <p>Price: $8 USD (per loaf)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'raspberryBread');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    */}
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductBreads;